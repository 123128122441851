
import { format } from "date-fns";
import { capitalize, get } from "lodash";
import QuickLinks from "@/components/QuickLinks/QuickLinks.vue";
// import UnderwriterDashboard from "@/components/UnderwriterDashboard/UnderwriterDashboard.vue";
// import ChartsDashboard from "@/components/ChartsDashboard/ChartsDashboard.vue";
import Vue from "vue";
import { authMapState } from "@/store/modules/auth";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
import { doFormatDateMMDDYYYY } from "@/store/modules/propertychangerequest/utils";
import { adminPrimaryMenu } from "../GlobalMenu/menuItemsAdmin";
import { userPrimaryMenu } from "../GlobalMenu/menuItemsUser";
import { agencyAdminPrimaryMenu } from "../GlobalMenu/menuItemsAgencyAdmin";
import { adjusterPrimaryMenu } from "../GlobalMenu/menuItemsAdjuster";
import { underwriterPrimaryMenu } from "../GlobalMenu/menuItemsUnderwriter";
import { claimsPrimaryMenu } from "../GlobalMenu/menuItemsClaims";
import { billingPrimaryMenu } from "../GlobalMenu/menuItemsBilling";
import { dataEntryPrimaryMenu } from "../GlobalMenu/menuItemsDataEntry";
interface IData {
  underwriterData: { name: string; email: string; phone: { primary: string } };
}

export default Vue.extend({
  name: "Dashboard",
  data(): IData {
    return {
      underwriterData: { name: "", email: "", phone: { primary: "" } }
    };
  },
  components: {
    QuickLinks,
    AdjusterPortalList: () =>
      import("@/components/Adjuster/ListPortal/AdjusterPortalList.vue")
  },
  mounted(): void {
    if (this.$getCurrentUser) {
      if (this.$getCurrentUser.agencyData) {
        const { underWriterData } = this.$getCurrentUser.agencyData;
        this.underwriterData = underWriterData;
      }
    }
  },
  methods: {
    displayNotificationMessage(message: string) {
      return message.length >= 168 ? `${message.slice(0, 168)}...` : message;
    },
    goToViewAllNotifications() {
      if (this.$isCurrentUserAdmin) {
        this.$router.push("/admin/notifications").catch();
      } else {
        this.$router.push("/notifications").catch();
      }
    },
    goToNotification(id: any) {
      const notificationRoute =
        this.$getCurrentUser.role === "admin"
          ? "/admin/notifications"
          : "/notifications";
      this.$router.push(`${notificationRoute}/${id}`).catch(() => {});
    },
    getIcon(priority: string): string {
      if (priority == "high") {
        return "arrow-up";
      } else if (priority == "medium") {
        return "arrows-h";
      } else {
        return "arrow-down";
      }
    },
    getIconClass(priority: string): string {
      if (priority == "high") {
        return "text-red-600";
      } else if (priority == "medium") {
        return "text-yellow-300";
      } else {
        return "text-green-600";
      }
    },
    doSetAdjusterErrorMessage(error: string): void {
      this.$appNotifyError(error);
    }
  },
  computed: {
    ...authMapState(["initData"]),
    displayNotifications(): any[] {
      if (this.initData && this.initData.unreadNotifications) {
        return this.initData.unreadNotifications.slice(0, 3);
      } else {
        return [];
      }
    },
    cellsToShow(): string[] {
      const role = this.$getCurrentUser?.role || "";

      const roleCellsMapper: Record<string, any> = {
        ezlynx: userPrimaryMenu(role),
        agent: userPrimaryMenu(role),
        admin: adminPrimaryMenu(),
        adjuster: adjusterPrimaryMenu(),
        subAdmin: agencyAdminPrimaryMenu(),
        agencyAdmin: agencyAdminPrimaryMenu(),
        billing: billingPrimaryMenu(),
        underwriting: underwriterPrimaryMenu(),
        claims: claimsPrimaryMenu(),
        dataEntry: dataEntryPrimaryMenu()
      };
      let dataToSend: string[] = [];
      const navData = roleCellsMapper[role] || [];

      navData.forEach((nav: Record<string, any>) => {
        let { to = "", text = "", subLinks = [] } = nav;

        to = to.toLowerCase();
        text = text.toLowerCase();

        if (to === "/quotes") {
          dataToSend.push("quote");
        }
        if (to === "/claims") {
          dataToSend.push("claims");
        }
        if (text === "policy") {
          subLinks.forEach((subLink: Record<string, string>) => {
            let { to = "" } = subLink;
            to = to.toLowerCase();
            if (to === "/change-requests") {
              dataToSend.push("billing", "payPlan", "property");
            }
            if (to === "/quickbillpay") {
              dataToSend.push("payBill");
            }
          });
        }
      });

      return dataToSend;
    },

    userName(): string {
      const { $getCurrentUser } = this;
      if (!$getCurrentUser) return "";
      const {
        firstName = "",
        lastName = "",
        displayName = ""
      } = $getCurrentUser;
      if (displayName) {
        return capitalizeFirstLetter(displayName);
      } else if (firstName || lastName) {
        return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
          lastName
        )}`;
      } else {
        return "User";
      }
    },
    role(): string {
      const role = this.$getCurrentUser?.role;
      return role === "agencyAdmin"
        ? "Agency Admin"
        : capitalize(this.$getCurrentUser?.role);
    },
    getDay(): string {
      return format(new Date(), "'Today is ' eeee");
    },
    getAgencyName(): string {
      return get(this.$getCurrentUser, "agencyData.agencyName", "");
    },
    agencyCode(): string {
      return get(this.$getCurrentUser, "agencyData.agencyCode", "");
    },
    underwriterAgencies(): any[] {
      return get(this.$getCurrentUser, "underWriterAgenciesData", []);
    },
    underwriterName(): string {
      const underwriterFirstName = capitalize(
        get(this.underwriterData, "firstName", "")
      );
      const underwriterLastName = capitalize(
        get(this.underwriterData, "lastName", "")
      );
      return `${underwriterFirstName} ${underwriterLastName}`;
    },
    underwriterPhone(): string {
      const phoneNumber = get(this.underwriterData, "phone", { primary: "" });
      return phoneNumber.primary;
    },
    underwriterEmail(): string {
      return get(this.underwriterData, "email", "");
    }
  },
  filters: {
    formatDate(d: string): string {
      return doFormatDateMMDDYYYY(d);
    }
  }
});
